export default [
  {
    title: '',
    fields: [
      {
        type: 'input',
        inputType: 'email',
        width: '100%',
        name: 'email',
        label: 'Email Address',
        required: true,
      },

      {
        type: 'input',
        inputType: 'password',
        width: '100%',
        name: 'password',
        label: 'Password',
        required: true,
        hideHint: true,
      },
      {
        type: 'submit',
        width: '100%',
        buttonLabel: 'Login',
      },
    ],
  },
];
