import React, { useContext } from 'react';
import { navigate } from 'gatsby';

import loginFields from './data';

import { FirebaseContext } from '../Firebase';
import Form from '../Form';

export default function LoginForm() {
  const firebase = useContext(FirebaseContext);

  const handleSubmit = ({ data, error }) => {
    const { email, password } = data;
    const { doSignInWithEmailAndPassword } = firebase;
    doSignInWithEmailAndPassword(email, password)
      .then(() => {
        navigate('/account/');
      })
      .catch(err => {
        error(err);
      });
  };
  return (
    <div style={{ width: `100%` }}>
      <Form
        name="login"
        fields={loginFields}
        action={handleSubmit}
        // style={loginForm}
        // debug
      />
    </div>
  );
}
